import React, { useCallback, useEffect, useRef } from "react"
import Seo from "../../components/SEO"
import { Link, graphql, useStaticQuery } from "gatsby"
import StarImage from "../../assets/img/star.png"
import ClutchLogo from "../../assets/img/clutch-new.png"
import bannerImageLeft from "../../assets/img/ppcBanner-left.png"
import bannerImageRight from "../../assets/img/ppcBanner-right.png"
import gradBg from "../../assets/img/grad-bg.webp"
import Love from "../../assets/img/love-icon.inline.svg"
import starGreen from "../../assets/img/star-green.png"
import starBlue from "../../assets/img/star-blue.png"
import starOrange from "../../assets/img/star-orange.png"
import bubbleBg from "../../assets/img/bubble-bg.webp"
import bubbleDesktop from "../../assets/img/bubble-bg-desktop.webp"
import centerCircle from "../../assets/img/center-circles.webp"

import ListTick from "../../assets/img/list-tick.png"
import { StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../../context/NavBarContext"
import FrontendIcon from "../../assets/img/frontend-developer.inline.svg"
import QaIcon from "../../assets/img/qa.inline.svg"
import EcommerceIcon from "../../assets/img/ecommerce.inline.svg"
import BackendIcon from "../../assets/img/backend-developer.inline.svg"
import UiIcon from "../../assets/img/ui-ux.inline.svg"
import Ba from "../../assets/img/business-analyst.inline.svg"
import CloudDeveloper from "../../assets/img/cloud-developers.inline.svg"
import Devops from "../../assets/img/devops.inline.svg"
import SoftwareDevelopers from "../../assets/img/software-developers.inline.svg"
import ProjectPortfolio from "../../components/PPC/Portfolio/PortfolioList"


import TestimonialSlider from "../../components/DashboardTestimonials/TestimonialSlider"
import Header from "../../components/PPC/HeaderPpcLanding"
import Faq from "../../components/PPC/ReactDevPpc/Faq"
import CorporateLocs from "../../components/CorporateLocs"
import ContactForm from "../../components/PPC/ContactFormLanding"
import { Stream, StreamPlayerApi } from "@cloudflare/stream-react"
import useIsInViewport from "../../helpers/useIsInViewport"
import AgencyReviewSlider from "../../components/AgencyReviewSlider"
import animatedIcon from "../../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../../assets/img/Contact-us-animation-white.gif"
import SocialMediaIcons from "../../components/SocialMediaIcons"

const PPCLanding = () => {
    const {
        allSoftwareDevelopersPpcFaqJson: { faq },
    } = useStaticQuery(graphql`
        query {
          allSoftwareDevelopersPpcFaqJson {
            faq: nodes {
              key
              question
              answer
            }
          }
        }
      `)

    const videoRef = useRef<StreamPlayerApi>()
    const element = useRef<HTMLDivElement>(null)

    const { isMobile, changeCurrentPath } = useContextValues()
    const isInView = useIsInViewport(element!)

    const playVideo = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.play()
        }
    }, [videoRef])

    const pauseVideo = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause()
        }
    }, [videoRef])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isInView) {
                playVideo()
            }
            if (!isInView) {
                pauseVideo()
            }
        }, 1000)

        return (() => {
            clearTimeout(timeoutId)
        })
    }, [isInView, playVideo, pauseVideo])

    useEffect(() => {
        changeCurrentPath(location.pathname)
    }, [])

    const goToSection = (id) => {
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
        <>
            <Header />
            <section className="pt-[7.625rem] md:pt-24 lg:pt-40 bg-[#FFFDFB] relative">
                <div className="container max-[480px]:px-[15px]">
                    <div className="max-w-[51.625rem] mx-auto text-center">
                        <h1 className="text-black text-[2.25rem] md:text-[2.75rem] lg:text-[3.25rem] leading-[3.125rem] lg:leading-[3.981rem] pb-[1.688rem] md:pb- lg:pb-5 font-blancomedium">
                            <span>Hire</span>
                            <span className="text-[#4BD965] italic"> software developers</span> to build quality custom softwares, web application and SaaS.
                        </h1>
                        <p className="text-base md:text-lg">
                            Experience top-notch software development with our team of over 80 expert developers. We specialize in creating innovative solutions for global clients, and our portfolio speaks for itself. Our developers are skilled in the most popular tech stacks, including React, Node.js, Laravel, and Angular. No matter what your project requires, we're ready to deliver exceptional results. Partner with us for reliable, cost-effective software development services that exceed your expectations.
                        </p>
                        <button
                            onClick={() => goToSection('contact-us-trial')}
                            className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium mt-10 mb-[1.875rem] max-md:mb-0 group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase"
                        >
                            Get a Free 30 Minutes Consultation
                        </button>
                        <div className=" mt-7 md:mt-0">
                            <button
                                onClick={() => goToSection('our-work')}
                                className=" text-lg font-gorditamedium underline relative before:content-[''] before:w-[7px] before:h-[7px] before:bg-[#FF3D2E] before:rounded-full before:absolute before:left-0 before:top-[9px] pl-[17px] inline-block"
                            >
                                Our Work
                            </button>
                            <button
                                onClick={() => goToSection('pricing')}
                                className=" text-lg font-gorditamedium underline relative before:content-[''] before:w-[7px] before:h-[7px] before:bg-[#FF3D2E] before:rounded-full before:absolute before:left-0 before:top-[9px] pl-[17px] inline-block ml-9"
                            >
                                Pricing
                            </button>
                        </div>
                        {/* <div className="relative after:content-[''] after:block after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[0.063rem] after:bg-gradient-to-r from-[#C9C9C900] from-10% via-[#C9C9C9] via-30% to-[#C9C9C900] to-90% max-md:after:hidden"></div> */}
                        <div className="text-center text-xs leading-[0.938rem] mt-5 max-md:mb-[3.125rem] md:mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle justify-center xl:absolute left-[50%] xl:translate-x-[-50%] bottom-[30px]">
                            <span className="text-sm md:text-base">4.9/5</span>
                            <span className="flex flex-nowrap items-center gap-[0.188rem]">
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                            </span>
                            <span>based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
                            <img
                                src={ClutchLogo}
                                alt="Clutch"
                                className="max-w-[3.75rem]"
                            />
                        </div>
                    </div>
                    <div className="banner-bottom-images flex flex-wrap justify-between md:mt-7 lg:mt-10 max-xl:justify-center max-[767px]:items-baseline">
                        <div className="left-images">
                            <img
                                src={bannerImageLeft}
                                alt="Web Developers"
                                className="inline-block h-full max-lg:h-auto max-[767px]:w-[185px] max-[402px]:w-[165px]"
                            />
                        </div>
                        <div className="right-images">
                            <img
                                src={bannerImageRight}
                                alt="Web Developers"
                                className="inline-block h-full max-lg:h-auto max-[767px]:w-[185px] max-[402px]:w-[165px]"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#FFFDFB] lg:pt-[6.125rem] lg:pb-[6.125rem] md:pb-11 pb-9 md:pt-[70px]">
                <div className="container max-w-[65rem] xl:px-0 relative z-10 flex items-center justify-center flex-wrap flex-col">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-8 font-blancomedium">We’re a fun, friendly, and<span className="text-[#4BD965] italic"> professional team</span> to work with.</h2>
                        <p className="text-lg">And we've been delivering world-class software for over 10+ years.</p>
                    </div>
                    <div className="mt-[2.875rem] max-w-[65rem] xl:px-0 relative z-10 flex items-center justify-center flex-wrap w-full md:w-[47.313rem] md:h-[27.375rem] max-[567]:h-[220px]">
                        <div ref={element} className="relative w-full h-full border border-primary p-3 rounded-3xl">
                            <span className="inline-block w-full h-full absolute left-0 top-0 p-3">
                                <StaticImage src="../assets/img/video-image-main2.webp" alt="Techuz Infoweb work environment" className="-z-10 h-full w-full rounded-2xl" />
                            </span>
                            <Stream
                                src={`https://customer-4iwgkzn69idft9ab.cloudflarestream.com/9aa1e5d252756e8160aa8a59e3343b17/iframe`}
                                className="h-full w-full top-0 left-0 overflow-hidden rounded-2xl p-0"
                                streamRef={videoRef}
                                responsive
                                title="Techuz Infoweb work environment"
                            />
                        </div>
                    </div>
                    <div className="btn-row text-center mt-[50px]">
                        <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Hire Web Developers</button>
                    </div>
                </div>
            </section>
            <section className="team-skills bg-[#F4F5F5] py-6 md:py-10 lg:py-[6.875rem]">
                <div className="container">
                    <div className="title">
                        <h2 className=" text-3xl  md:text-4xl lg:text-[2.625rem] leading-9 md:leading-[3.216rem] text-center lg:max-w-[51.75rem] mx-auto">
                            <span>Build team on different skills + Quality work </span>
                            <span className="text-[#4BD965] italic">= Faster product development</span>
                        </h2>
                        <p className="mt-5 text-lg leading-[1.875rem] text-center lg:max-w-[50.875rem] mx-auto">
                            Our engineering team utilizes Agile practices with modern tools like JIRA, Slack, Trello, Github, CI/CD, and AWS, ensuring seamless collaboration from day one, just like your in-house team.
                        </p>
                        <ul className="team-list grid grid-cols-2 md:grid-cols-3 gap-x-[1.438rem] gap-y-4 mt-[2.188rem] lg:max-w-[50.875rem] mx-auto">
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <FrontendIcon className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">Web Development</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <BackendIcon className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">Software Development</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <UiIcon className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">UI/UX</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <EcommerceIcon className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">E-commerce</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <Ba className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">Business Analysts</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <Devops />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">DevOps Engineers</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <CloudDeveloper className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">Cloud Engineering</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <QaIcon className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">QA</span>
                            </li>
                            <li className="flex flex-wrap items-center py-[0.813rem] px-4 rounded-[0.563rem] bg-gradient-to-b from-[#fff] to-transparent justify-center">
                                <SoftwareDevelopers className="w-[2.063rem]" />
                                <span className="inline-block leading-4 max-md:w-full w-[calc(100% - 33px)] max-md:pt-[10px] md:pl-[0.625rem]">Scrum Masters</span>
                            </li>
                        </ul>
                        <div className="btn-row text-center mt-[40px] md:mt-[70px]">
                            <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Schedule A Developer's Interview</button>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing" className="py-10 md:py-12 lg:py-[55px] pb-10">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Assemble your software developers team starting at an <span className="text-[#4BD965] italic">affordable price.</span></h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] mt-8 md:mt-[3.125rem] text-center lg:max-w-[950px] lg:mx-auto">
                        <div className=" p-5 lg:py-12 lg:px-10 border-2 border-[#FFF2E3] rounded-[1.25rem]">
                            <p className="font-blancomedium text-4xl leading-[44.1px] mb-[49px] max-md:mb-5 max-md:text-3xl">I want to hire  <span className="text-[#4BD965] italic">dedicated development team</span></p>
                            <span className="font-gorditamedium text-xl lg:text-2xl italic inline-block mb-5"> Start from </span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[50px]"><span className="text-[2.875rem] lg:text-[3.375rem] text-3xl">$15</span>/Hour</div>
                            <button
                                onClick={() => goToSection('contact-us-trial')}
                                className="inline-flex gap-5 items-center bg-transparent justify-start inline-white text-black border border-[#3FB955] shadow-1xl text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-[#3FB955] hover:text-white uppercase"
                            >
                                DISCUSS YOUR NEEDS
                            </button>
                        </div>

                        <div className="p-5 lg:py-12 lg:px-10 border-2 border-[#fff9f2] rounded-[1.25rem] bg-[#fff9f2]">
                            <p className="font-blancomedium text-4xl leading-[44.1px] mb-[49px] max-md:mb-5 max-md:text-3xl">I need  <span className="text-[#4BD965] italic">project-based</span> delivery</p>
                            <span className="font-gorditamedium text-xl lg:text-2xl italic inline-block mb-5"> Start from </span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[0.625rem]"><span className="text-[2.875rem] lg:text-[3.375rem] text-3xl">$5000</span></div>
                            <button
                                onClick={() => goToSection('contact-us-trial')}
                                className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-base rounded-lg font-gorditamedium mt-10 group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase"
                            >
                                GET A QUOTE
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section id="our-work" className="lg:pt-[50px] lg:pb-[50px] md:pb-11 pb-0">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-8 font-blancomedium">Projects<span className="text-[#4BD965] italic"> powered by</span> our development team.</h2>
                    </div>
                    <ProjectPortfolio />
                </div>
            </section>
            <section id="contact-us-trial" className="py-10 md:py-12 lg:py-[55px] lg:pt-[110px] bg-[#FFFDFB]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-[1.875rem] md:pb-[3.125rem] font-blancomedium">Onboard a developer with<span className="text-[#4BD965] italic"> 1 week risk-free trial.</span></h2>
                    </div>
                    <div className="contact-form-part lg:max-w-[852px] lg:mx-auto grid md:grid-cols-[295px_1fr] rounded-[36px] border-[2px] border-[#FFF2E3]">
                        <div className="py-10 px-[2.188rem] relative z-10">
                            <span className="inline-block absolute left-0 top-0 h-full w-full -z-[1]">
                                <img
                                    src={gradBg}
                                    alt="Web Developers"
                                    className="inline-block w-full h-full max-md:rounded-t-[36px] max-md:rounded-b-[36px]"
                                />
                            </span>
                            <p className=" text-lg leading-[1.875rem] mb-10">We ensure you are matched with the right resource based on your requirements.</p>
                            <ul>
                                <li className="relative pl-12 mb-10">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    We will respond to you within 24 hours.
                                </li>
                                <li className="relative pl-12 mb-10">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    We’ll sign a NDA if requested.
                                </li>
                                <li className="relative pl-12">
                                    <img
                                        src={ListTick}
                                        alt="tick icon"
                                        className="inline-block md:w-8 mr-3 absolute left-0 top-0"
                                    />
                                    Access to dedicated product specialists.
                                </li>
                                <li className="flex flex-col pb-[1.875rem] md:pb-[3.125rem] md:mt-10">
                                    <h3 className="font-blancomedium text-black pb-2 text-lg">
                                        Inquires
                                        <span className="inline-block text-orangecol">.</span>
                                    </h3>
                                    <a
                                        href="mailto:sales@techuz.com"
                                        className="md:text-[1.5rem] text-xl font-gorditamedium underline leading-[1.875rem] text-black transition-all duration-500 hover:text-primary"
                                    >
                                        sales@techuz.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="form-wrapper  rounded-[2.125rem] py-10 px-[2.188rem] bg-white-200">
                            <ContactForm
                                submitBtnTxt="Submit"
                                currency={'USD'}
                                spacingCss="md:pb-[1.5rem]"
                                submitBtnCss="text-white bg-primary min-[1441px]:text-[1.25rem] max-[1440px]:text-[1.15rem] text-base text-center rounded-[1.875rem] px-3 py-4 max-[1440px]:py-[0.75rem] w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
                                formCss="rounded-xl"
                                NameCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                emailCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                phoneNumberCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                budgetCss="select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] appearance-none !text-sm leading-[1.375rem] w-full text-black pt-[0.875rem] pb-3 !px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                textAreaCss="align-top h-40 max-[1440px]:h-24 resize-none !text-sm w-full text-black py-3 !px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                formTitle="Get in touch with technical experts."
                            />
                        </div>
                    </div>
                </div>
            </section>



            {!isMobile && <section className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">Testimonials</span></div>
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            Here’s what <span className="italic text-black md:text-primary">our clients say</span> about us
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
                    </div>

                    <TestimonialSlider />

                </div>
            </section >}

            <section id="technical-stack" className="py-10 md:py-12 lg:pt-[55px] lg:pb-[110px]">
                <div className="container max-md:p-0">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto max-md:p-5">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Technical stack.</h2>
                        <p className="text-lg md:max-w-[45rem] mx-auto mt-3">We use best-in-class tools, state-of-the-art technologies and modern approaches to scale up your business</p>
                    </div>
                    <div className="stack-wrapper mt-[1.875rem]">
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full ">FrontEnd</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Angular</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Vue.JS</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">React.JS</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">jQuery</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Three JS</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 rounded-lg md:mb-[1.688rem] max-md:px-0 md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">BackEnd</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Node JS</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Laravel</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Ruby On Rails</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">PHP</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">.Net</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Golang</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Django</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Mobile</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Flutter</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">React Native</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">iOS</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Android</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Ionic</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Server</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">AWS</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">Digital Ocean</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">Heroku</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:min-w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Database</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">MongoDB</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">PostgreSQL</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">MySQL</span>
                            </p>
                        </div>
                        <div className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex lg:block">
                            <p className="lg:pl-[1.688rem] bg-[#E8E3FF] lg:min-w-[185px] xl:min-w-[217px] md:w-[100px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-xl font-gorditamedium inline-block max-md:mb-4 max-md:w-full">Version Control</p>
                            <p className="inline-block lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-3 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10">
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">GitHub</span>
                                <span className="pr-3 mr-3 inline-block relative before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%] max-md:text-sm md:text-base lg:text-xl">BitBucket</span>
                                <span className="pr-3 inline-block relative max-md:text-sm md:text-base lg:text-xl">GitLab</span>
                            </p>
                        </div>
                    </div>
                    <div className="btn-row text-center mt-[50px]">
                        <button className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Schedule A Developer's Interview</button>
                    </div>
                </div>
            </section>


            <section className="md:pt-[6.875rem] py-10 md:pb-[6.875rem] bg-[#FFFDFB] relative z-10">
                <span className="bg-image absolute left-0 top-0 w-full h-full -z-[1]">
                    <img
                        src={bubbleBg}
                        alt="domain experts"
                        className="w-full h-full md:hidden"
                    />
                    <img
                        src={bubbleDesktop}
                        alt="domain experts"
                        className="w-full h-full max-md:hidden"
                    />
                </span>
                <span className="lg:inline-block w-10 h-10 absolute left-[43%] translate-x-[-50%] hidden">
                    <img
                        src={centerCircle}
                        alt="domain experts"
                        className="w-full h-[36px]"
                    />
                </span>
                <div className="container">
                    <div className="grid md:grid-cols-[174px_1fr] lg:grid-cols-[264px_1fr] md:gap-x-[3.75rem]">
                        <div className="title">
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-8 font-blancomedium">Find <span className="text-[#4BD965] italic"> domain experts</span> like</h2>
                            <div className="btn-row">
                                <button aria-current="page" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-6 md:px-[2.063rem] hover:bg-transparent hover:text-black uppercase" onClick={() => goToSection('contact-us-trial')}>Get Started</button>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-[180px_180px_180px] md:grid-cols-3 grid-cols-2 gap-5 max-md:mt-7 justify-end">
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starGreen}
                                    alt="Healthcare"
                                    className="mb-5"
                                />
                                Healthcare
                            </div>
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starBlue}
                                    alt="Edtech"
                                    className="mb-5"
                                />
                                Edtech
                            </div>
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starOrange}
                                    alt="e-Commerce"
                                    className="mb-5"
                                />
                                e-Commerce
                            </div>
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starBlue}
                                    alt="SaaS"
                                    className="mb-5"
                                />
                                SaaS
                            </div>
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starOrange}
                                    alt="Finance"
                                    className="mb-5"
                                />
                                Finance
                            </div>
                            <div className=" bg-white-200 p-6 shadow-[0_0_48px_0_rgba(0,0,0,0.1)] rounded-[0.438rem] font-gorditamedium">
                                <img
                                    src={starGreen}
                                    alt="Real Estate"
                                    className="mb-5"
                                />
                                Real Estate
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" py-6 md:py-10 lg:py-[6.875rem]">
                <div className="container">
                    <div className="title">
                        <h2 className="text-3xl md:text-4xl lg:text-[2.625rem] leading-[3.216rem] text-center">
                            <span>Awarded for</span>
                            <span className="text-[#4BD965] italic"> best work.</span>
                        </h2>
                    </div>
                    <AgencyReviewSlider />
                </div>
            </section>
            <Faq faqData={faq} />
            <section className="contact-us py-10 md:py-20 bg-[#414142]">
                <div className="container">
                    <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
                        <ul>
                            <li className="flex flex-col pb-[1.875rem] md:pb-[3.125rem]">
                                <h3 className="font-blancomedium text-white pb-2">
                                    Inquires
                                    <span className="inline-block text-orangecol">.</span>
                                </h3>
                                <a
                                    href="mailto:sales@techuz.com"
                                    className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                >
                                    sales@techuz.com
                                </a>
                                <a
                                    href="mailto:hr@techuz.com"
                                    className="md:text-[1.5rem] text-xl font-gorditaregular underline leading-[1.875rem] text-white transition-all duration-500 hover:text-primary mt-[10px]"
                                >
                                    hr@techuz.com
                                </a>
                            </li>
                            <li className="flex flex-col pb-7">
                                <h3 className="font-blancomedium text-white pb-2">
                                    Reach us at
                                    <span className="inline-block text-orangecol">.</span>
                                </h3>
                                <div className="flex items-center pb-2">
                                    <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                                        <StaticImage src="../assets/img/wp.png" alt="" />
                                    </span>
                                    <a
                                        target="_blank"
                                        href="https://wa.me/message/ZPEQ4QXOJTEDD1"
                                        className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                    >
                                        +91 95125 59090
                                    </a>
                                    <span className="text-sm font-gorditaregular text-white ml-1">( Sales )</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="bg-[#2A2A2D] w-9 h-9 flex item-center justify-center mr-[0.625rem] rounded-full items-center">
                                        <StaticImage src="../assets/img/wp.png" alt="" />
                                    </span>
                                    <a
                                        href="tel:9512559091"
                                        className="md:text-[1.125rem] text-base font-gorditaregular leading-[1.875rem] text-white transition-all duration-500 hover:text-primary"
                                    >
                                        +91 95125 59091
                                    </a>
                                    <span className="text-sm font-gorditaregular text-white ml-1">( HR )</span>
                                </div>
                            </li>
                            <li className="flex flex-col md:pt-[2.625rem] pt-0 pb-[1.875rem] md:pb-0">
                                <SocialMediaIcons />
                            </li>
                        </ul>
                        <div className="max-[567px]:w-full md:w-[440px] lg:w-[660px] h-[340px] max-md:h-[270px] contact-form leading-[2.375rem] pt-10 md:pt-14 pb-10 md:pb-[4.875rem] px-[1.875rem] rounded-[1.875rem] text-center lg:px-[3.125rem] relative z-10 bg-white-200 shadow-[0_9px_0_rgba(42,42,45,1)]">
                            <h2 className="text-black text-2xl sm:text-[1.75rem] lg:text-[2.5rem] lg:leading-[3.375rem] pb-12 font-blancomedium text-center">
                                Get the <i>real estimates</i> and <br /> <i>ideal solutions</i> for your project
                                <span className="inline-block text-orangecol">.</span>
                            </h2>
                            <button
                                onClick={() => goToSection('contact-us-trial')}
                                className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 font-gorditamedium group ease-in duration-300"
                            >
                                Get an estimate
                                <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={animatedIconWhite}
                                        alt=""
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                                    />
                                    <img
                                        src={animatedIcon}
                                        alt=""
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                                    />
                                </span>
                            </button>
                            <StaticImage src="../assets/img/tech-bg-new.webp" alt="" className="absolute w-full h-full top-0 left-0 -z-10 rounded-[1.875rem] " />
                        </div>
                    </div>
                </div>
            </section>
            <footer className="bg-[#151516] pt-14">
                <div className="text-center container">
                    <Link to="/" className="logo mb-6 w-full md:hidden block text-left">
                        <StaticImage
                            src="../assets/img/techuz-logo-white.png"
                            alt="Techuz Infoweb"
                            className="inline max-w-[6.563rem]"
                            class="relative w-auto"
                            width={105}
                            height={35.45}
                            placeholder="blurred"
                        />
                    </Link>
                    <h4 className="text-white md:text-sm text-xs flex items-center md:justify-center justify-start leading-7 md:pb-6 pb-2.5">
                        Made with
                        <Love className="mx-1" />
                        in India and serving worldwide
                    </h4>
                    <ul className="flex uppercase text-[#979797] md:justify-center justify-start text-xl font-gorditaregular flex-wrap md:flex-nowrap">
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            USA
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            UK
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            Australia
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            SINGAPORE
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            INDIA
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            UAE
                        </li>
                        <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
                            EUROPE
                        </li>
                    </ul>
                </div>
                <CorporateLocs />
                <div className="footer-btm md:bg-[#09090A] bg-[#09090A] md:py-4 pt-4 pb-[0.875rem] md:mt-[3.75rem] mt-[1.875rem]">
                    <div className="container">
                        <div className="flex md:justify-between justify-center items-center flex-wrap md:flex-nowrap">
                            <ul className="flex items-center md:mb-0 mb-3.5">
                                <li className="leading-4 relative mr-4 pr-4 after:content[''] after:absolute after:bg-grey after:w-[0.063rem] after:h-3 after:top-1/2 after:right-0 after:translate-y-[-50%]">
                                    <Link
                                        to="/privacy-policy/"
                                        className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="leading-4">
                                    <Link
                                        to="/terms/"
                                        className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                </li>
                            </ul>
                            <p className="text-[#808080] text-xs md:mb-0 md:w-auto w-full text-center">
                                Copyright {new Date().getFullYear()} © Techuz InfoWeb Private
                                Limited
                            </p>
                        </div>
                    </div>
                </div>

            </footer>

        </>
    )
}
export const Head = () => <Seo title="Software Developers India" noIndex />

export default PPCLanding